import { CompanyRole } from 'shared/models/CompanyRole.model'
import { User } from 'interfaces/User'

export type AdviceRoleId = number

export enum AdviceRoleType {
  CUSTOMER = 'customer',
  SUPPLIER = 'supplier',
  FORWARDER = 'forwarder',
  CARRIER = 'carrier',
  DRIVER = 'driver',
  OUTBOUND = 'outbound',
  INBOUND = 'inbound',
}

export const TRANSPORT_ROLE_NAMES = [AdviceRoleType.FORWARDER, AdviceRoleType.CARRIER]

export default interface AdviceRole extends CompanyRole<AdviceRoleType> {
  user?: User
  uuid?: string
}
